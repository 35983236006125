import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import {IPopularGamesComponentProps} from '../../../lib/types/components';
import {dynamicCssSize, isiOSDevice, isMobile} from "../../../lib/utils/constants/PagesConstants";
import {NextArrowV2, PrevArrowV2} from "../../common/ArrowComponent/ArrowComponentV2";
import CustomLinkComponent from "../../common/CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import ButtonClickAnimation from "../../animation/ButtonClickAnimation";
import React, {useEffect, useState} from "react";
import dynamic from "next/dynamic";

const PopularGamesSliderDesktopLazy = dynamic(() => import("./PopularGamesSliderDesktop"), {
    ssr: true
});

const PopularGamesComponentV2 = (props: IPopularGamesComponentProps) => {
    const {popularGamesData, winzoTagLineProps} = props;

    return (
        popularGamesData && Object.keys(popularGamesData).length ?
            <H2TrapeziumBG>
                <style jsx>{`
                  .parentContainer {
                    background: linear-gradient(180deg, rgba(241, 73, 41, 1) 0%, rgba(211, 12, 73, 1) 42%, rgba(85, 11, 96, 1) 86%, rgba(34, 1, 50, 1) 100%),
                    url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/pattern_top_games.webp");
                    background-blend-mode: color;
                    background-size: cover;
                    background-repeat: no-repeat;
                    padding-bottom: 4.6vw;
                  }

                  .mainContainer {
                    padding: 18px 0px;
                    font-family: var(--font-primary);
                    color: white;
                  }

                  .tagline {
                    display: none;
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    text-align: center;
                    background-clip: text;
                    line-height: normal;
                    color: #fff;
                    margin: 0;
                  }

                  .heading {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: 10px;
                  }

                  .heading > h2 {
                    max-width: 65vw;
                    text-align: center;
                    font-weight: 900;
                    font-style: italic;
                    letter-spacing: 2.25px;
                    font-size: ${dynamicCssSize(60, 16)};
                    text-transform: uppercase;
                    color: white;
                  }

                  .sliderParent {
                    padding: 20px 0px;
                  }

                  .slideItem {
                    position: relative;
                  }

                  #popularGamesAnimLink {
                    cursor: pointer;
                    padding-top: 10px;
                  }

                  .slideImage {
                    display: flex;
                    align-items: end;
                    justify-content: center;
                    position: relative;
                    bottom: 20px;
                    width: 80%;
                    height: ${dynamicCssSize(320, 160)};
                    transition: all 0.5s ease;
                    border-radius: 2vw;
                    overflow: hidden;
                    margin: auto;
                    -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0.1)));
                  }

                  /* Hide scrollbar for Chrome, Safari and Opera */
                  .mobileInnerSliderContainer::-webkit-scrollbar {
                    display: none;
                  }

                  /* Hide scrollbar for IE, Edge and Firefox */
                  .mobileInnerSliderContainer {
                    display: none;
                    padding: 8px;
                    overflow: scroll;
                    width: 100%;
                    height: 100%;
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                  }

                  .mobileSliderItem {
                    margin: 0 5px;
                  }

                  .mobGameName {
                    margin-top: 8px;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.56;
                    text-align: center;
                    text-transform: uppercase;
                  }

                  @media screen and (max-width: 767px) {
                    .mainContainer {
                      padding: 5px 0px;
                    }

                    .mobImgContainer {
                      position: relative;
                      width: 107px;
                      height: 83px;
                      overflow: hidden;
                      border-radius: 10px;
                    }
                    
                    .sliderParent {
                      padding: 0px 0px;
                    }

                    .mobileInnerSliderContainer {
                      display: flex;
                    }

                    .heading {
                      padding-bottom: ${dynamicCssSize(40, 10)};
                      border-bottom: 1px solid;
                      border-image-slice: 1;
                      border-image-source: linear-gradient(to right, rgba(255, 255, 255, .2) 3%, #f6c401 57%, rgba(255, 255, 255, .1) 99%);
                      margin-bottom: 20px;
                    }

                    .tagline {
                      display: block;
                    }
                  }
                `}</style>
                <div className={"container-fluid parentContainer"}>
                    <div className={"container mainContainer"}>
                        <div className={"heading row"}>
                            <h2>{popularGamesData.heading}</h2>
                        </div>
                        <div style={{marginBottom: 20}}>
                            <h1 className="col tagline">
                                {isiOSDevice
                                    ? winzoTagLineProps.winzoTagLineIos
                                        ? winzoTagLineProps.winzoTagLineIos
                                        : winzoTagLineProps.WinzoTagLineData
                                    : winzoTagLineProps.WinzoTagLineData}
                            </h1>
                        </div>
                        <div className="row">
                            <div className={"col sliderParent"}>
                                {<PopularGamesSliderDesktopLazy {...popularGamesData} />}
                                <div className={"mobileInnerSliderContainer"}>
                                    {
                                        popularGamesData.games.map((item, index) => {
                                            return (
                                                <ButtonClickAnimation key={"popularGamesData.games_" + index}>
                                                    <CustomLinkComponent href={item.url}
                                                                         mobStyle={{textDecoration: "none", color: "white"}}
                                                                         desktopStyle={{textDecoration: "none", color: "white"}}
                                                    >
                                                        <div className={"mobileSliderItem mobImgContainer"}>
                                                            <CustomImageComponent
                                                                layout={'fill'}
                                                                src={item.src}
                                                                alt={`Play ${item.name} Online`}
                                                            />
                                                        </div>

                                                        <p className={"mobGameName"}>{item.name}</p>
                                                    </CustomLinkComponent>
                                                </ButtonClickAnimation>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default PopularGamesComponentV2;